<template>
  <!-- 扫码入职 -->
  <div class="saoEntry">
    <van-nav-bar
      class="van_sao"
      title="扫码入职"
      left-text=""
      left-arrow
      @click-left="onClickLeft"
      @click-right="onClickRight"
    />
    <div class="line"></div>
    <div class="ewm">
      <div class="ewm_header"></div>
      <div class="ewm_content">
      <img src="@/assets/img/viewInfo_ewm.png" />
      <span>欢迎加入华跃科技</span>
      </div>
    </div>
    <van-button type="default">保存二维码</van-button>
  </div>
</template>

<script>
export default {
  name: "saoEntry",
  methods: {
    onClickLeft() {
      this.$router.push("/applyEntry");
    },
    onClickRight() {},
  },
};
</script>

<style lang="less" scoped>
.saoEntry {
  position: relative;
  height: 100vh;
  background: linear-gradient(180deg, #33baff 0%, #2b8df0 100%);
  .van_sao {
    background: #33baff;
    height: 92px;
    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #fff;
      }
    }
    /deep/.van-nav-bar__title {
      color: #fff;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
    /deep/ .van-nav-bar__right {
      .van-nav-bar__text {
        color: #fff;
        font-size: 32px;
      }
    }
  }
  .line {
    position: absolute;
    width: calc(100% - 60px);
    left: 50%;
    transform: translateX(-50%);
    top: 298px;
    height: 22px;
    background: #1259a0;
    opacity: 1;
    border-radius: 18px;
  }
  .ewm {
    position: absolute;
    top: 310px;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% - 100px);
    height: 752px;
    background: #fff;
    box-shadow: 0px 12px 20px #135EA5;
    .ewm_header {
      position: absolute;
      top: 0px;
      width:100%;
      height: 62px;
      background: linear-gradient(
        180deg,
        rgba(43, 141, 240, 0.76) 0%,
        rgba(255, 255, 255, 0) 100%
      );
    }
    .ewm_content{
        position: absolute;
        padding:30px;
        text-align: center;
        box-sizing: border-box;
        width: 528px;
        height: 528px;
        border:1px dashed #000;
        top:50%;
        left:50%;
        transform: translate(-50%,-50%);
        img{
          width:100%;
          height:100%;
        }
        span{
          font-size: 44px;
          color:#333;
        }
    }
  }
  .van-button{
    position: absolute;
    top:1108px;
      left: 50%;
    transform: translateX(-50%);
    width:calc(100% - 100px);
    height:102px;
    color:#2B8DF0;
    font-size: 40px;
  }
}
</style>
